/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import $ from 'jquery';
import Axios from 'axios';
import Blank from '../images/empty.jpg';
import Loading from '../images/loading.gif';



import "./formrelation.css";

class OpeenseesCircJacket extends React.Component{
    //javascript
    state = {
      val1: 5,
      val2: 2,
      val3: 15,
      val4: 12,
      val5: 2,
      val6: 0.03,
      val7: 0.5,
      val8: 0.03,
      val9: -20000,
      val10: -0.002,
      val11: -0.0035,
      val12: 30000000,
      val13: 2200,
      val14: 0.001,
      val15: 400000,
      val16: 200000000,
      val17: 0.01,
      val18: -25000,
      val19: -0.002,
      val20: -0.0035,
      val21: 30000000,
      val22: 2200,
      val23: 0.001,
      val24: 500000,
      val25: 205000000,
      val26: 0.01,
      val27: 20,
      val28: 30,
      val29: 8,
      val30: 0.75,
      val31: 25,
      val32: 30,
      val33: 10,
      val34: 0.1,
      val35: 550,
      val36: 0.5,
      ColumnGenerator: 'Circular-Jacket',
      src: Blank,
      errorclass: '',
    }
    handleInputChange = event => {
      const target = event.target
      const value = target.value
      const name = target.name
      const value2 = value.replace(",",".");
      
      this.setState({
        [name]: value2,
      })
    }
    
    handleSubmit = async e => {
      e.preventDefault();
      
      this.setState({
        src: Loading,
      })
      
      //development graph image
      //let path = 'https://www.mathsisfun.com/data/images/line-graph-example.svg';

      // this.setState({
      //   src: path,
      // })

      
      await Axios.get('https://thebridgedatabase.pythonanywhere.com/CantileverBeam?ColumnGenerator='+this.state.ColumnGenerator+'&val1='+this.state.val1+'&val2='+this.state.val3+'&val3='+this.state.val3+'&val4='+this.state.val4+'&val5='+this.state.val5+'&val6='+this.state.val6+'&val7='+this.state.val7+'&val8='+this.state.val8+'&val9='+this.state.val9+'&val10='+this.state.val10+'&val11='+this.state.val11+'&val12='+this.state.val12+'&val13='+this.state.val13+'&val14='+this.state.val14+'&val15='+this.state.val15+'&val16='+this.state.val16+'&val17='+this.state.val17+'&val18='+this.state.val18+'&val19='+this.state.val19+'&val20='+this.state.val20+'&val21='+this.state.val21+'&val22='+this.state.val22+'&val23='+this.state.val23+'&val24='+this.state.val24+'&val25='+this.state.val25+'&val26='+this.state.val26+'&val27='+this.state.val27+'&val28='+this.state.val28+'&val29='+this.state.val29+'&val30='+this.state.val30+'&val31='+this.state.val31+'&val32='+this.state.val32+'&val33='+this.state.val33+'&val34='+this.state.val34+'&val35='+this.state.val35+'&val36='+this.state.val36,{
        headers: {
          "Access-Control-Allow-Origin" : "*",
          "mode":"cors",
        }
      })
        .then(result => {
            
            let response = result.data;
            this.setState({
              src: Blank,
            })
            // let imageurl = result.data;
            // this.setState({
            //   src: imageurl,
            // })
            console.log('Success1') // Actually see what is returned.
           
        })
        .catch(err => {
          this.setState({
            src: Blank,
            errorclass: 'errorfetch',
          })
          
            
        })

        await Axios.get('https://thebridgedatabase.pythonanywhere.com/PlotPushOver?ColumnGenerator='+this.state.ColumnGenerator,{
        headers: {
          "Access-Control-Allow-Origin" : "*",
          "mode":"cors",
        }
      })
        .then(result => {
            
            
            let imageurl = result.data;
            this.setState({
              src: imageurl,
            })
            console.log('Success2') // Actually see what is returned.
           
        })
        .catch(err => {
          this.setState({
            src: Blank,
            errorclass: 'errorfetch',
          })
          
            
        })
        
      
    };
    
  
    
    render(){
        
        
      return(
          <>
        <form onSubmit={this.handleSubmit}>
          <div className="group-fields">
            <h2>Element Properties</h2>
            <label>
            H(m):
            <input
                type="text"
                name="val1"
                value={this.state.val1}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Height of Pier</div></div>
            </label>

            <label>
            Num. of Elements:
            <input
                type="text"
                name="val2"
                value={this.state.val2}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Number of elements - Mesh</div></div>
            </label>

            <label>
            Fibers Perimeter:
            <input
                type="text"
                name="val3"
                value={this.state.val3}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Number of Fibers along Perimeter</div></div>
            </label>


            <label>
            Fibers Radius:
            <input
                type="text"
                name="val4"
                value={this.state.val4}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Number of Fibers along Radius</div></div>
            </label>
          </div>
          <div className="group-fields">
           <h2>Section Properties</h2>
           <h3>Geometry</h3>
           <label>
            D<sub>core</sub> (m):
            <input
                type="text"
                name="val5"
                value={this.state.val5}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Diameter of core section</div></div>
            </label>
            <label>
            C<sub>core</sub> (m):
            <input
                type="text"
                name="val6"
                value={this.state.val6}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Cover of core section</div></div>
            </label>
            <label>
            d<sub>RC,jacket</sub> (m):
            <input
                type="text"
                name="val7"
                value={this.state.val7}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">RC Jacket thickness </div></div>
            </label>
            <label>
            c<sub>RC,jacket</sub> (m):
            <input
                type="text"
                name="val8"
                value={this.state.val8}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">RC Jacket Cover</div></div>
            </label>
            <h3>Materials</h3>
            <h4>1. Concrete of core section (<a href="https://opensees.berkeley.edu/wiki/index.php/Concrete04_Material_--_Popovics_Concrete_Material" target="_blank">OpenSees Material 04</a>)*</h4>
           <label>
           fco<sub>, core</sub> (kPa):
            <input
                type="text"
                name="val9"
                value={this.state.val9}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Concrete compressive strength (unconfined) of core section</div></div>
            </label>
            <label>
            ec<sub>, core</sub>:
            <input
                type="text"
                name="val10"
                value={this.state.val10}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Concrete strain at maximum strength of core section</div></div>
            </label>
            <label>
            ecu<sub>, core</sub>:
            <input
                type="text"
                name="val11"
                value={this.state.val11}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Concrete strain at crushing strength of core section</div></div>
            </label>
            <label>
            Ec<sub>, core</sub> (kPa):
            <input
                type="text"
                name="val12"
                value={this.state.val12}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Initial Stiffness of core section (see Opensees Mat04)</div></div>
            </label>
            <label>
            fct<sub>, core</sub> (kPa):
            <input
                type="text"
                name="val13"
                value={this.state.val13}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Maximum tensile strength of concrete of core section</div></div>
            </label>
            <label>
            et<sub>, core</sub>:
            <input
                type="text"
                name="val14"
                value={this.state.val14}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Ultimate tensile strain of concrete of core section</div></div>
            </label>
            <div className="reference-star">Confined concrete properties estimated according to Mander et al. (1988)<span>Mander, J. B., Priestley, M. J. N., and Park, R. (1988). "Theoretical stress-strain model for confined concrete." Journal of Structural Engineering ASCE, 114(8), 1804-1825</span></div>
            <h4>2. Reinforcement steel of core section (<a href="https://opensees.berkeley.edu/wiki/index.php/Steel02_Material_--_Giuffr%C3%A9-Menegotto-Pinto_Model_with_Isotropic_Strain_Hardening" target="_blank">OpenSees Material 02</a>)</h4>
            <label>
            fy<sub>, core</sub> (kPa):
            <input
                type="text"
                name="val15"
                value={this.state.val15}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Yield strength of reinforcement steel of core section</div></div>
            </label>
            <label>
            E<sub>0, core</sub> (kPa):
            <input
                type="text"
                name="val16"
                value={this.state.val16}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Initial Elastic Tangent of core section (see Opensees Mat02)</div></div>
            </label>
            <label>
            b<sub>, core</sub>:
            <input
                type="text"
                name="val17"
                value={this.state.val17}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Strain - Hardening ratio (ratio between post-yield tangent and initial elastic tangent) of core section (see Opensees Mat02)</div></div>
            </label>
            <h4>3. Concrete of jacket (<a href="https://opensees.berkeley.edu/wiki/index.php/Concrete04_Material_--_Popovics_Concrete_Material" target="_blank">OpenSees Material 04</a>)*</h4>
           <label>
           fco<sub>,RC jacket</sub> (kPa):
            <input
                type="text"
                name="val18"
                value={this.state.val18}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Concrete compressive strength (unconfined) of RC jacket</div></div>
            </label>
            <label>
            ec<sub>,RC jacket</sub>:
            <input
                type="text"
                name="val19"
                value={this.state.val19}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Concrete strain at maximum strength of RC jacket</div></div>
            </label>
            <label>
            ecu<sub>,RC jacket</sub>:
            <input
                type="text"
                name="val20"
                value={this.state.val20}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Concrete strain at crushing strength of RC jacket</div></div>
            </label>
            <label>
            Ec<sub>,RC jacket</sub> (kPa):
            <input
                type="text"
                name="val21"
                value={this.state.val21}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Initial Stiffness of RC jacket (see Opensees Mat04)</div></div>
            </label>
            <label>
            fct<sub>,RC jacket</sub> (kPa):
            <input
                type="text"
                name="val22"
                value={this.state.val22}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Maximum tensile strength of concrete of RC jacket</div></div>
            </label>
            <label>
            et<sub>,RC jacket</sub>:
            <input
                type="text"
                name="val23"
                value={this.state.val23}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Ultimate tensile strain of concrete of RC jacket</div></div>
            </label>
            <div className="reference-star">Confined concrete properties estimated according to Mander et al. (1988)<span>Mander, J. B., Priestley, M. J. N., and Park, R. (1988). "Theoretical stress-strain model for confined concrete." Journal of Structural Engineering ASCE, 114(8), 1804-1825</span></div>
            <h4>4. Reinforcement steel of jacket (<a href="https://opensees.berkeley.edu/wiki/index.php/Steel02_Material_--_Giuffr%C3%A9-Menegotto-Pinto_Model_with_Isotropic_Strain_Hardening" target="_blank">OpenSees Material 02</a>)</h4>
            <label>
            fy<sub>, RC jacket</sub> (kPa):
            <input
                type="text"
                name="val24"
                value={this.state.val24}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Yield strength of reinforcement steel of RC jacket</div></div>
            </label>
            <label>
            E<sub>0, RC jacket</sub> (kPa):
            <input
                type="text"
                name="val25"
                value={this.state.val25}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Initial Elastic Tangent of RC jacket (see Opensees Mat02)</div></div>
            </label>
            <label>
            b<sub>, RC jacket</sub>:
            <input
                type="text"
                name="val26"
                value={this.state.val26}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Strain - Hardening ratio (ratio between post-yield tangent and initial elastic tangent) of RC jacket (see Opensees Mat02)</div></div>
            </label>
            <h3>Reinforcement (core)</h3>
            <label>
             Φ<sub>L, core</sub> (mm):
            <input
                type="text"
                name="val27"
                value={this.state.val27}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Diameter of Longitudinal Reinforcement  of core section</div></div>
            </label>
            <label>
            Num. of Long. Reinf. Bars of core:
            <input
                type="text"
                name="val28"
                value={this.state.val28}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Total number of longitudinal reinforcement bars of core section</div></div>
            </label>
            <label>
            Φ<sub>w, core</sub> (mm)::
            <input
                type="text"
                name="val29"
                value={this.state.val29}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Diameter of Transverse Reinforcement of core section</div></div>
            </label>
            <label>
            s<sub>core</sub> (m):
            <input
                type="text"
                name="val30"
                value={this.state.val30}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Distance of transverse reinforcement of core section</div></div>
            </label>
            <h3>Reinforcement (jacket)</h3>
            <label>
             Φ<sub>L, RC jacket</sub> (mm):
            <input
                type="text"
                name="val31"
                value={this.state.val31}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Diameter of Longitudinal Reinforcement of RC jacket</div></div>
            </label>
            <label>
            Num. of Long. Reinf. Bars of RC jacket:
            <input
                type="text"
                name="val32"
                value={this.state.val32}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Total number of longitudinal reinforcement bars of RC jacket</div></div>
            </label>
            <label>
            Φ<sub>w, RC jacket</sub> (mm)::
            <input
                type="text"
                name="val33"
                value={this.state.val33}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Diameter of Transverse Reinforcement of RC jacket</div></div>
            </label>
            <label>
            s<sub>RC jacket</sub> (m):
            <input
                type="text"
                name="val34"
                value={this.state.val34}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Distance of transverse reinforcement of RC jacket</div></div>
            </label>
          </div>
          <div className="group-fields">
            <h2>Analysis Properties &amp; Loading</h2>
            <label>
            N (kN):
            <input
                type="text"
                name="val35"
                value={this.state.val35}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Vertical Load (G+0.3Q)</div></div>
            </label>
            <label>
            d<sub>target</sub> (m):
            <input
                type="text"
                name="val36"
                value={this.state.val36}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Target Displacement for Pushover Analysis</div></div>
            </label>
          </div>
          <button type="submit">Calculate</button>
            
      </form>
      <p className={`errormessage ${this.state.errorclass}`}>The system encountered a problem. Please <a href="/contact">contact us</a> to report this error.</p>
      <img className="opensees-image" src={this.state.src} />
    </>
      )
    }
}
export default OpeenseesCircJacket

import React from "react"
import Layout from "../../../../../components/layout"
import SEO from "../../../../../components/seo"
import Opensees from "../../../../../components/openseescircjacket"
import { Link } from 'gatsby'
import Image from "../../../../../images/Retrofitted.jpg"





const LSAnalyticalJacket = () => {
  //javascript
  const title = 'LS Thresholds: Analytical estimation - Cylindrical Piers Retrofitted with RC Jacket';
  const metatitle = 'LS Thresholds: Analytical estimation - Cylindrical Piers Retrofitted with RC Jacket';
  const description = 'Meta Description for LS Thresholds: Analytical estimation - Cylindrical Piers Retrofitted with RC Jacket';
  const metadescription = description;
  return(
    <Layout bodyclass="ls-analytical" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <nav className="anchornav">
            <ul>
             <li key="1">
                <Link className="more actclass" to="/bridge-specific/capacity/retrofitted/ls-analytical/rcjacket">Cylindrical Piers Retrofitted with RC Jacket</Link>
              </li>
              <li key="2">
                <Link className="more" to="/bridge-specific/capacity/retrofitted/ls-analytical/frpjacket">Cylindrical Piers Retrofitted with FRP Jacket</Link>
              </li>
          </ul>
        </nav>
        <div className="content">
          <div className="api">
            <Opensees/>
          </div>
        </div>
        <a class="analytical-img" href={Image} target="_blank"><img src={Image} /></a>
      
    </Layout>
  )
}

export default LSAnalyticalJacket
